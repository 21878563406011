<template>
  <div class="detail">
    <detail-header title="资讯详情" />
    <div class="detail_main page_width">
      <div class="info">
        <p class="info_title tw-text-20px tw-font-bold">{{formData.title}}</p>
        <p class="info_time tw-text-14px">{{formData.updatedAt}}</p>
        <div class="info_content mce-content-body" v-html="formData.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    return {
      formData: {}
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  mounted () {
    this.getInfoDetail()
  },
  methods: {
    getInfoDetail () {
      api.getInfoDetail(this.id).then(res => {
        if (res.data.code === 0) {
          this.formData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="scss">
@import url('../../assets/styles/content.min.scss');
</style>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
  }
}
.info {
  width: 960px;
  margin: 0 auto;
  &_title {
    text-align: center;
    color: #303133;
    line-height: 28px;
    padding: 16px 0;
  }
  &_time {
    color: #909399;
    line-height: 20px;
    text-align: center;
  }
  &_content {
    margin-top: 20px;
    img {
      max-width: 100%;
    }
  }
}
</style>
